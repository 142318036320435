.stamp {
    font-size: 20px;
    font-weight: bold;
    color: white;
    padding: 10px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 5px rgba(0,0,0,0.5);
    opacity: 0.85;
    user-select: none;
    position: absolute;
    top: 10%;
    right: 20px;
}